import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerSearchPageComponent } from './pages/customer-search-page/customer-search-page.component';
// import { MsalRedirectComponent } from './pages/msal-redirect/msal-redirect.component';

const routes: Routes = [
  {
    path: 'customer-lookup',
    component: CustomerSearchPageComponent
  },
  { path: '', redirectTo: 'customer-lookup', pathMatch: 'full' },
  { path: '**', redirectTo: 'customer-lookup' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
