<div class="container" *ngIf="loggedIn$ | async">
  <div class="search-container">
    <mat-form-field class="search-field resolve-spacer" appearance="outline">
      <mat-label>{{ 'clientSearch.search' | translate }}</mat-label>
      <input matInput class="search-input" [formControl]="searchControl" (keyup.enter)="search()" placeholder= "{{ 'clientSearch.searchPlaceholder' | translate }}" autocomplete="off">
      <button matSuffix mat-icon-button aria-label="search" (click)="search()" >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="table-container" *ngIf="customers && customers.length > 0">
    <customer-details-table [data]="customers" [isLoading]="isLoading"></customer-details-table>
  </div>
</div>