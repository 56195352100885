<div class="container">
  <mat-card class="message-wrapper">
    <p class="message-header">{{ 'msal.timeoutHeader' | translate }}</p>
    <p class="message">{{ 'msal.timeoutMessage' | translate }}</p>
    <p class="timer">{{ countdown * 1000 | date: 'mm:ss' }}</p>
    <button
      mat-raised-button
      color="primary"
      (click)="closePopup()"
      class="button">
      {{ 'msal.reset' | translate }}
    </button>
  </mat-card>
</div>
