import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { from, last, map, mergeAll, mergeMap, Observable, of, Subscription } from 'rxjs';
import { Client } from '../..//models/client.model';
import { CDCService } from '../..//services/cdc.service';
import { LoadingService } from '../..//services/loading.service';
import { LocationService } from '../..//services/location.service';
import { MsAuthService } from '../..//services/ms-auth.service';
import { MsGraphService } from '../..//services/ms-graph.service';

@Component({
  selector: 'app-customer-search-page',
  templateUrl: './customer-search-page.component.html',
  styleUrls: ['./customer-search-page.component.scss']
})
export class CustomerSearchPageComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public customers: Client[] = [];
  public searchControl: FormControl<string> = new FormControl<string>(null);
  public loggedIn$: Observable<boolean>;
  private _searchText: string;
  private _subscriptions = new Subscription();

  constructor(
    private _cdcService: CDCService,
    private _msAuthService: MsAuthService,
    private _msGraphService: MsGraphService,
    private _loadingService: LoadingService,
    private _locationService: LocationService,
  ) {
    this.loggedIn$ = _msAuthService.loggedIn$;
  }

  ngOnInit(): void {
    this._msAuthService.loggedIn$.subscribe((loggedin) => {
      if (!loggedin) {
        this.searchControl.patchValue(null);
        this.customers = [];
      }
    })

    this._msAuthService.resolveToken$.subscribe(_ => {
      this._locationService.getStores().subscribe();
    })

    this._subscriptions.add(
      this.searchControl.valueChanges.subscribe((val) => {
        this._searchText = val == null || val.trim().length == 0 ? null : val.trim();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _searchCustomers(): void {
    if (!this._searchText) {
      this.customers = [];
      return;
    }

    this._loadingService.startLoading();
    this.isLoading = true;

    let clients: Client[] = null;
    this._cdcService.clientSearch(this._searchText)
      .pipe(
        map((data: Client[]) => {
          if (!data || data.length == 0) return of(null);
          clients = data;
          if (!this._locationService.stores) return from(clients);
          clients.forEach(client => {
            if (!!client.storeId) {
              client.storeName = `${this._locationService.stores.find((store) => store.id === client.storeId.toString())?.name || ''}`;
            }
          })
          return from(clients);
        }),
        mergeAll(),
        mergeMap((client: Client, index) => {
          return !!client && !!client.advisorId ?
            this._msGraphService.fetchEmployeeInfoById(client.advisorId)
              .pipe(
                map((infoResult) => {
                  if (!!infoResult) {
                    clients[index].advisorName = infoResult.displayName;
                  }
                }))
            : of(client);
        }),
        last()
      )
      .subscribe({
        next: (_) => {
          this.customers = clients;
          this._loadingService.stopLoading();
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.customers = [];
          this._loadingService.stopLoading();
          this.isLoading = false;
        }
      })
  }

  search(): void {
    this._searchCustomers();
  }
}