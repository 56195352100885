<div class="container">
  <div>
    <img src="../../../assets/images/aritzia_logo.svg" alt="Aritzia" />
    <h2 class="header-text">{{ 'banner.header' | translate }}</h2>
  </div>
  <div style="text-align: right">
    <div class="toggle">
      <mat-label class="toggle-label">{{
        'banner.english' | translate
        }}</mat-label>
      <mat-slide-toggle
              [(ngModel)]="frenchLang"
              (change)="toggleLanguage($event.checked)">
        {{ 'banner.french' | translate }}
      </mat-slide-toggle>
    </div>
    <button style="margin:5px" class="login-button" mat-raised-button color=primary *ngIf="!(loggedIn$ | async)" (click)="buttonPressed(status)" mat-button>
      {{ 'msal.login' | translate }}
    </button>
    <button style="margin:5px" class="login-button" mat-raised-button color=primary *ngIf="loggedIn$ | async" (click)="buttonPressed(status)" mat-button>
      {{ 'msal.logout' | translate }}
    </button>
  </div>
</div>
