import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalModule } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { NgIdleModule } from '@ng-idle/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerComponent } from './components/banner/banner.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { ErrorService } from './services/error.service';
import { LoadingService } from './services/loading.service';
import { MsAuthService } from './services/ms-auth.service';
import { TokenService } from './services/token.service';
import { DropDownComponent } from './shared/drop-down/drop-down.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { TimeoutPopupComponent } from './shared/timeout-popup/timeout-popup.component';
import { DateRangePickerComponent } from './shared/date-range-picker/date-range-picker.component';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { CustomerSearchPageComponent } from './pages/customer-search-page/customer-search-page.component';
import { LocationService } from "./services/location.service";
import { CustomerDetailsTable } from './components/customer-details-table/customer-details-table.component';
import { PopupModalComponent } from "./shared/popup-modal/popup-modal.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
// import { MsalRedirectComponent } from './pages/msal-redirect/msal-redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    LoadingSpinnerComponent,
    DropDownComponent,
    TimeoutPopupComponent,
    DateRangePickerComponent,
    CustomerSearchPageComponent,
    CustomerDetailsTable,
    PopupModalComponent,
    // MsalRedirectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSortModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: process.env.NG_APP_AZURE_CLIENT_ID,
          authority:
            'https://login.microsoftonline.com/78f57c94-422f-440b-a0c8-906018bb649a',
          redirectUri: environment.azure.redirectUri,
          postLogoutRedirectUri: environment.azure.redirectUri
        },
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage
        }
      }),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ['user.read', 'user.read.all'],
          prompt: 'login'
        }
      },
      null
    ),
    NgIdleModule.forRoot(),
    MatChipsModule,
    MatSlideToggleModule
  ],
  providers: [
    LoadingService,
    ErrorService,
    MsAuthService,
    TokenService,
    DatePipe,
    LocationService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}
