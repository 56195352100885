import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ErrorService } from './error.service';
import { MsAuthService } from './ms-auth.service';

export interface IMSGraphResponse {
  "@odata.context": string,
  value: IEmployeeInfo[]
}

export interface IEmployeeInfo {
  displayName: string;
}

@Injectable({
  providedIn: 'root'
})
export class MsGraphService {
  private _host = environment.msGraphAPI.host;
  constructor(
    private _msAuthService: MsAuthService,
    private _httpClient: HttpClient,
    private _errorService: ErrorService,
  ) { }

  public fetchEmployeeInfoById(employeeId: number): Observable<IEmployeeInfo> {
    if (!this._msAuthService.isLoggedIn()) return of(null);
    const url = `${environment.apigee.host}/external/microsoft/graph/v1/users`;
    const query = `$search="employeeId:${employeeId}"&$select=displayName&ConsistencyLevel=eventual`
    
    return this._httpClient
      .get<IMSGraphResponse>(`${url}?${query}`)
      .pipe(
        map((result: IMSGraphResponse) => {
          if (result.value && result.value.length > 0) {
            return result.value[0];
          } else {
            return null;
          }
        }),
        catchError((e) => {
          this._errorService.showGeneralError(true);
          throw e;
        })
      );
  }
}
