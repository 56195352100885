import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Client } from '../models/client.model';
import { ErrorService } from './error.service';
import { MsAuthService } from './ms-auth.service';

export interface ICDCResponse {
    errorCode: number;
    apiVersion: number;
    statusCode: number;
    statusReason: string;
    time: string;
    results: {
        data: ICDCData;
        profile: ICDCProfile;
        preferences?: ICDCPreferences;
    }[];
    objectsCount: number;
    totalCount: number;
}

export interface ICDCData {
    customer: {
        phone: {
            number: string;
            countryCode: string;
        };
        consent: {
            isEmailable: boolean;
            isCallable: boolean;
            isTextable: boolean;
            isStyleAdvisorEmailable: boolean;
            isStyleAdvisorCallable: boolean;
            isStyleAdvisorTextable: boolean;
        };
        event: {
            ID: string;
            channel: string;
            endDate: string;
            group: string;
            name: string;
            primaryCountry: string;
            primaryRegion: string;
            primaryState: string;
            primaryStore: string;
            snapshotDate: string;
            spendRange: string;
            startDate: string;
            styleAdvisor: string;
            tier: string;
            totalSpend: string;
            type: string;
        }
    };
}

export interface ICDCProfile {
    lastName: string;
    email: string;
    firstName: string;
}

export interface ICDCPreferences {
    loyaltyProgramEnrollment?: {
        isConsentGranted?: boolean
    }
}

@Injectable({
    providedIn: 'root',
})
export class CDCService {
    public clients: Client[] = [];
    private _host = `${environment.apigee.host}${environment.cdc.servicePath}`;

    constructor(
        private _httpClient: HttpClient,
        private _errorService: ErrorService,
        private _msAuthService: MsAuthService
    ) { }

    public clientSearch(searchText: string): Observable<Client[]> {
        if (!this._msAuthService.isLoggedIn()) return of(null);
        const url = `${this._host}/${environment.cdc.uris.accountSearch}`;
        let fields: string[] = ['profile.firstName', 'profile.lastName', 'profile.email', 'data.customer.phone', 'data.customer.event', 'data.customer.styleAdvisor', 'preferences.loyaltyProgramEnrollment.isConsentGranted'];
        const query = `SELECT ${fields} FROM accounts WHERE \
        ( \
            (data.customer.fullName CONTAINS '${searchText}') \
            OR ( data.customer.email = regex('.*${searchText}.*') ) \
            OR ( data.customer.phone.number = regex('.*${searchText}.*') ) \
        ) \
        and (data.customer.event.group is not null) \
        ORDER BY data.customer.event.group, data.customer.fullName \
        limit 10`;

        const queryString = new URLSearchParams({ query });
        return this._httpClient
            .post<ICDCResponse>(`${url}?${queryString.toString()}`, undefined)
            .pipe(
                map((result: ICDCResponse) => {
                    if (!result || !result.results || result.results.length == 0) return null;

                    this.clients = result.results.map((profile) =>
                        new Client(profile.data, profile.profile, profile.preferences)
                    );

                    if (result.totalCount > 10) {
                        this._errorService.showError('errors.refineSearch');
                    }
                    return this.clients;
                }),
                catchError((e) => {
                    this._errorService.showGeneralError(true);
                    throw e;
                })
            );
    }

}