import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private _showError: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showError$: Observable<string> = this._showError.asObservable();

  private _showGeneralError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public showGeneralError$: Observable<boolean> =
    this._showGeneralError.asObservable();

  constructor(
    private _translateService: TranslateService) {}

  public showError(error: string): void {
    const val = this._translateService.instant(error);
    this._showError.next(val);
  }

  public showGeneralError(val: boolean): void {
    this._showGeneralError.next(val);
  }
}
