import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MsAuthService } from './ms-auth.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private tokenService: TokenService, // skipcq
    private msAuthService: MsAuthService,

  ) { }

  intercept(
    request: HttpRequest<any>, // skipcq
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url === '/assets/i18n/en.json') {
      // handles loading the language assest
      return next.handle(request);
    }

    // skipcq
    if (request.headers.get('skip')) {
      // Handles token request
      request = request.clone({
        headers: request.headers.delete('skip', 'true'),
      });
      return next.handle(request);
    }

    if (!this.msAuthService.token) {
      this.msAuthService.fetchAccessToken();
    }
 
    const token = this.tokenService.token;
    if (!token) {
      // Generate token if expired
      return this.tokenService.initialize().pipe(
        switchMap(() => {
          request = request.clone({
            withCredentials: true
          });
          return next.handle(request).pipe(
            catchError((err) => {
              return throwError(err);
            })
          );
        })
      );
    } else {
      request = request.clone({
        withCredentials: true
      });
      return next.handle(request).pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }
  }
}
