import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MsalGuardAuthRequest,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  PopupRequest,
  SilentRequest
} from '@azure/msal-browser';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, catchError, map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { TimeoutPopupComponent } from '../shared/timeout-popup/timeout-popup.component';
import { ErrorService } from './error.service';

export interface IMsalToken {
  accessToken: string;
  expiresOn: number;
  tokenType: string;
  scopes: string[];
}

@Injectable({
  providedIn: 'root',
})
export class MsAuthService {
  private _silentToken: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public silentToken$: Observable<string | undefined> =
    this._silentToken.asObservable();

  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.isLoggedIn()
  );
  public loggedIn$: Observable<boolean> = this._loggedIn.asObservable();

  private _resolveToken: Subject<string | null> = new Subject<
    string | null
  >();
  public resolveToken$: Observable<string | undefined> =
    this._resolveToken.asObservable();

  private _timeoutPrompted: boolean = false;
  private _tokenTimer: any;
  private _accessToken: AuthenticationResult;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private _msalAuthService: MsalService,
    private _idle: Idle,
    private _dialog: MatDialog,
    private _error: ErrorService
  ) {
    _idle.setIdle(environment.idle.inactive); // how long can they be inactive before considered idle, in seconds
    _idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    _idle.onIdleStart.subscribe(() => {
      if (!this._timeoutPrompted) this._promptTimeoutPopup();
    });

    if (this.isLoggedIn()) {
      this._setIdle();
      this.fetchAccessToken();
    }
  }

  private _setIdle() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this._idle.watch();
  }

  private _promptTimeoutPopup() {
    this._timeoutPrompted = true;
    this._dialog
      .open(TimeoutPopupComponent, {
        hasBackdrop: false,
      })
      .afterClosed()
      .subscribe((reset?: boolean) => {
        this._timeoutPrompted = false;
        if (reset) {
          this._setIdle();
        } else {
          this.logout();
        }
      });
  }

  // MS login
  public login(): void {
    if (this.msalGuardConfig.authRequest) {
      this._msalAuthService
        .loginPopup({
          ...this.msalGuardConfig.authRequest,
        } as PopupRequest)
        .subscribe({
          next: (result: AuthenticationResult) => {
            this._loggedIn.next(this.isLoggedIn());
            this._setIdle();
            this._setAccessToken(result);
          },
          error: (error) => this._error.showError('errors.login'),
        });
    } else {
      this._msalAuthService.loginPopup()
        .subscribe({
          next: (result: AuthenticationResult) => {
            this._loggedIn.next(this.isLoggedIn());
            this._setIdle();
            this._setAccessToken(result);
          },
          error: (error) => this._error.showError('errors.login'),
        });
    }
  }

  // MS logout
  public logout(): void {
    const accounts = this._msalAuthService.instance.getAllAccounts();

    if (accounts && accounts.length > 0) {
      this._msalAuthService
        .logoutPopup({
          account: accounts[0],
        })
        .subscribe({
          next: (done) => {
            this._loggedIn.next(this.isLoggedIn());
            this._idle.stop();
          },
          error: (error) => this._error.showError('errors.logout'),
        });
    }
  }

  // Check if is logged in
  public isLoggedIn(): boolean {
    return (
      !!this._msalAuthService.instance &&
      this._msalAuthService.instance.getAllAccounts().length > 0
    );
  }

  // Auth token related methods
  // Commented for now, might use this in the future
  // Fetch access token
  async fetchAccessToken(refresh?: boolean) {
    if (this.isLoggedIn()) {
      await this._msalAuthService
        .acquireTokenSilent({
          ...this.msalGuardConfig.authRequest,
          account: this._msalAuthService.instance.getAllAccounts()[0],
          forceRefresh: refresh,
        } as SilentRequest)
        .subscribe({
          next: (result: AuthenticationResult) => {
            this._setAccessToken(result);
          },
          error: (error) => this._error.showError('errors.token'),
        });
    }
  }

  private _setAccessToken(authResult: AuthenticationResult): void {
    if (authResult && authResult.accessToken) {
      this._accessToken = authResult;
      this._silentToken.next(authResult.accessToken);
      this._resolveToken.next(authResult.accessToken);
      this.tokenUpdater();
    }
  }

  private tokenUpdater(): void {
    if (this._tokenTimer) {
      return;
    } else {
      this._tokenTimer = setTimeout(() => {
        this._tokenTimer = undefined;
        if (this._accessToken && new Date() > this._accessToken.expiresOn) {
          this._accessToken = undefined;
          this.fetchAccessToken();
        } else {
          this.tokenUpdater();
        }
      }, 30000);
    }
  }

  public get token(): AuthenticationResult | undefined {
    return this._accessToken;
  }

  public hasToken(): boolean {
    return this.isLoggedIn() && this._accessToken != undefined;
  }
}