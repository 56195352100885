import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorService } from './services/error.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public isIframe = false;
  public showGeneralError = false;

  constructor(
    private _errorService: ErrorService,
    private _snackBar: MatSnackBar,
  ) {
    this._errorService.showError$.subscribe((err) => {
      if (!!err) {
        this._snackBar.open(err, 'OK',{
          panelClass: 'error-snack-bar'
        });
      }
    });

    this._errorService.showGeneralError$.subscribe((val) => {
      this.showGeneralError = val;
    });
  }

  public ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
