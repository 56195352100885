import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { last, Subscription, take, tap, timer } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss'],
})
export class TimeoutPopupComponent implements OnDestroy {
  private _timerSubscription: Subscription = null;
  countdown: number = environment.idle.timeout;

  constructor(private _dialogRef: MatDialogRef<TimeoutPopupComponent>) {
    this._timerSubscription = timer(0, 1000)
      .pipe(
        take(environment.idle.timeout),
        tap((timing) => {
          this.countdown -= 1;
        }),
        last()
      )
      .subscribe((_) => {
        this._dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    this._timerSubscription.unsubscribe();
  }

  closePopup() {
    this._dialogRef.close(true);
  }
}
