import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Client } from 'src/app/models/client.model';



@Component({
  selector: 'customer-details-table',
  templateUrl: './customer-details-table.component.html',
  styleUrls: ['./customer-details-table.component.scss'],
})
export class CustomerDetailsTable {
  @Input() isLoading = false;
  @Input() data: Client[];

  public displayedColumns = ['name', 'email', 'phone', 'tier', 'group', 'store', 'advisor', 'vic'];

  constructor(
  ) {
  }
}
