<div class="table-loading-container mat-elevation-z8">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <table mat-table [dataSource]="data" class="table">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="name-column medium-column">
        {{ 'clientTableDetail.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="name-column medium-column">
        {{customer.name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef class="email-column large-column">
        {{ 'clientTableDetail.email' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="email-column large-column">
        {{customer.email}}
      </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef class="phone-column medium-column">
        {{ 'clientTableDetail.phone' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="phone-column medium-column">
        {{customer.phone}}
      </td>
    </ng-container>

    <ng-container matColumnDef="tier">
      <th mat-header-cell *matHeaderCellDef class="tier-column small-column">
        {{ 'clientTableDetail.tier' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="tier-column small-column">
        {{customer.tier}}
      </td>
    </ng-container>
    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef class="group-column small-column">
        {{ 'clientTableDetail.group' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="group-column small-column">
        {{customer.group}}
      </td>
    </ng-container>
    <ng-container matColumnDef="store">
      <th mat-header-cell *matHeaderCellDef class="store-column medium-column">
        {{ 'clientTableDetail.primaryStore' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="store-column medium-column">
        {{customer.storeName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="advisor">
      <th mat-header-cell *matHeaderCellDef class="advisor-column medium-column">
        {{ 'clientTableDetail.primaryStyleAdvisor' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="advisor-column medium-column">
        {{customer.advisorName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="vic">
      <th mat-header-cell *matHeaderCellDef class="vic-column small-column">
        {{ 'clientTableDetail.vic' | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="vic-column small-column">
        {{customer.veryImportant ? 'Yes' : ''}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row, columns: displayedColumns;" class="customer-detail-row"></tr>
  </table>
</div>