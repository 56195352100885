import { Component } from '@angular/core';
import { Observable} from 'rxjs';
import { MsAuthService } from '../../services/ms-auth.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  public status = false;
  public frenchLang = false;
  public loggedIn$: Observable<boolean>;

  constructor(
    private _msAuthService: MsAuthService,
    private _translate: TranslateService) {
    this._msAuthService.loggedIn$.subscribe((val) => this.status = val);

    this._translate.setDefaultLang('en');
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.frenchLang = lang === 'fr';
      this._translate.use(lang);

    }
  }

  ngOnInit() {
    this.loggedIn$ = this._msAuthService.loggedIn$;
  }

  public buttonPressed(loggedIn: boolean):void {
    if(loggedIn) {
      this._msAuthService.logout();
    } else {
      this._msAuthService.login();
    }
  }

  public toggleLanguage(checked: boolean): void {
    if (checked) {
      this.frenchLang = true;
      localStorage.setItem('lang', 'fr');
      this._translate.use('fr');
    } else {
      this.frenchLang = false;
      localStorage.setItem('lang', 'en');
      this._translate.use('en');
    }
  }

}
