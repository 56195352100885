import { ICDCData, ICDCPreferences, ICDCProfile } from '../services/cdc.service';

export class Client {
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _phone: string;
  private _tier: string;
  private _group: string;

  private _storeId: number;
  private _storeName: string;
  private _advisorId: number;
  private _advisorName: string;

  private _veryImportant: boolean;

  constructor(data: ICDCData, profile?: ICDCProfile, preferences?: ICDCPreferences) {
    if (profile) {
      this._firstName = profile.firstName;
      this._lastName = profile.lastName;
      this._email = profile.email;
    }

    if (data && data.customer) {
      if (data.customer.phone) {
        if (data.customer.phone.countryCode && data.customer.phone.number) {
          this._phone = `${data.customer.phone.countryCode} ${data.customer.phone.number}`;
        } else {
          this._phone = data.customer.phone.number;
        }
      }
      if (data.customer.event) {
        this._storeId = data.customer.event.primaryStore == null || isNaN(Number(data.customer.event.primaryStore)) ? undefined : Number(data.customer.event.primaryStore);

        if (data.customer.event.tier) {
          this._tier = data.customer.event.tier
        }

        if (data.customer.event.group) {
          this._group = data.customer.event.group;
        }

        if (data.customer.event.styleAdvisor) {
          this._advisorId = Number(data.customer.event.styleAdvisor);
        }
      }
    }

    if(preferences && preferences.loyaltyProgramEnrollment){
      this._veryImportant = preferences.loyaltyProgramEnrollment.isConsentGranted;
    }
  }

  public get name(): string {
    if (!!this._firstName && !!this._lastName) {
      return `${this._firstName} ${this._lastName}`;
    } else if (!!this._firstName) {
      return this._firstName;
    } else if (!!this._lastName) {
      return this._lastName;
    } else {
      return '';
    }
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get email(): string {
    return this._email;
  }

  public get phone(): string {
    return this._phone;
  }

  public get tier(): string {
    return this._tier;
  }

  public get group(): string {
    return this._group;
  }

  public get storeId(): number {
    return this._storeId;
  }

  public get storeName(): string {
    return this._storeName;
  }

  public set storeName(name: string) {
    this._storeName = name;
  }

  public get advisorId(): number {
    return this._advisorId;
  }

  public get advisorName(): string {
    return this._advisorName;
  }

  public set advisorName(name: string) {
    this._advisorName = name;
  }

  public get veryImportant(): boolean {
    return this._veryImportant === true;
  }

  public set veryImportant(vic: boolean) {
    this._veryImportant = vic;
  }
}